<template>
  <div>
    <!-- Error display -->
    <slot name="alert">
      <base-alert v-model="showAlert" :dismissible="dismissibleAlert">{{
        error
      }}</base-alert>
    </slot>

    <!-- Details router-view -->
    <v-scroll-x-transition>
      <div v-show="!visibleDataTableIn">
        <router-view></router-view>
      </div>
    </v-scroll-x-transition>

    <!-- content -->
    <v-scroll-x-transition>
      <v-card v-show="visibleDataTableIn" :loading="loading">
        <v-container fluid>
          <slot name="default">
            <!-- breadcrumbs -->
            <template v-if="hasSlot('breadcrumbs')">
              <v-row dense>
                <v-col>
                  <slot name="breadcrumbs"></slot>
                </v-col>
              </v-row>
            </template>

            <!-- module details -->
            <template v-if="hasSlot('module-details')">
              <v-row dense>
                <v-col>
                  <slot name="module-details"></slot>
                </v-col>
              </v-row>
            </template>

            <template v-if="hasSlot('module-details-list')">
              <v-row dense>
                <v-col>
                  <slot name="module-details-list"></slot>
                </v-col>
              </v-row>
            </template>

            <!-- record details -->
            <template v-if="hasSlot('record-details')">
              <v-row dense>
                <v-col>
                  <slot name="record-details"></slot>
                </v-col>
              </v-row>
            </template>

            <!-- record projects -->
            <template v-if="hasSlot('record-projects')">
              <v-row dense>
                <v-col>
                  <slot name="record-projects"></slot>
                </v-col>
              </v-row>
            </template>

            <!-- record List -->
            <template v-if="hasSlot('record-list')">
              <v-row dense>
                <v-col>
                  <!--
                    remarks: make Document search result list by default opened,
                    it really save a lot of clicks
                  -->
                  <!--                  <expansion-panels-layout-->
                  <!--                    :open="true"-->
                  <!--                    :icon-name="iconFileMultiple"-->
                  <!--                  >-->
                  <!--                    {{ expansionPanelFilesHeader }}-->
                  <!--                    <template v-slot:content>-->
                  <slot name="record-list"></slot>
                  <!--                    </template>-->
                  <!--                  </expansion-panels-layout>-->
                </v-col>
              </v-row>
            </template>

            <!-- record history -->
            <template v-if="hasSlot('record-history')">
              <v-row dense>
                <v-col>
                  <expansion-panels-layout :icon-name="iconHistory">
                    {{ fileHistoryHeader }}
                    <template v-slot:content>
                      <slot name="record-history"></slot>
                    </template>
                  </expansion-panels-layout>
                </v-col>
              </v-row>
            </template>

            <!-- record-file preview -->
            <template v-if="hasSlot('record-preview')">
              <v-row dense>
                <v-col>
                  <expansion-panels-layout
                    :icon-name="iconPreview"
                    v-on:expanded="onExpandedPreview"
                  >
                    {{ recordPreviewHeader }}
                    <template v-slot:content>
                      <slot
                        name="record-preview"
                        v-bind:expanded="expandedPreview"
                      ></slot>
                    </template>
                  </expansion-panels-layout>
                </v-col>
              </v-row>
            </template>
          </slot>
        </v-container>
      </v-card>
    </v-scroll-x-transition>

    <!-- Snackbar -->
    <slot name="snackbar"></slot>
  </div>
</template>

<script>
// design
import {
  iconFileMultiple,
  iconHistory,
  iconFilePreview
} from "@/design/icon/iconConst";

// model
import { eventNames } from "@/model/common/events/eventConst";

// services
import { fullRecordName } from "@/services/record/recordService";

// mixins
import { alertableErrorMixin } from "@/mixins/shared/alertable/alertableErrorMixin";
import { slottableMixin } from "@/mixins/shared/slottable/slottableMixin";

export default {
  name: "BaseContentLayout",
  mixins: [alertableErrorMixin, slottableMixin],
  data() {
    return {
      iconHistory: iconHistory,
      iconFileMultiple: iconFileMultiple,
      iconPreview: iconFilePreview,
      expandedPreview: false
    };
  },
  components: {
    ExpansionPanelsLayout: () =>
      import("@/components/shared/core/expansionPanel/ExpansionPanelsLayout")
  },
  props: {
    /**
     * Current Record
     * @type {{id:number, name:string, categoryId:number, recordTypeId:number, recordType: string, parentId:number, children:number, createdBy:string, creationDate:string, extension:string, isComposite:boolean, isLink:boolean, isReadOnly:boolean, isComposite: boolean, isDeleted: boolean, isDraft: boolean, isLink: boolean, isLocked: boolean, stateId:number, state:string, version: number, versionDate: string, versionOwner: string, fieldValues: {id: number, name:string, fieldDataType: number, fieldDataTypeName: string, value: string}[] }}
     */
    record: undefined,

    /**
     * Is visible Data Table
     */
    visibleDataTableIn: {
      type: Boolean,
      default: true
    },

    /**
     * Is loading indicator
     */
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    /**
     * computed full Record Name
     * @return {string|string}
     */
    fullRecordName() {
      return this.record ? fullRecordName(this.record) : "";
    },

    /**
     * expansion Panel File History Header
     * @return {string}
     */
    fileHistoryHeader() {
      return `History of ${this.fullRecordName}`;
    },

    /**
     * expansion Panel Record Preview Header
     * @return {string} Record Preview Header
     */
    recordPreviewHeader() {
      return `Preview of ${this.fullRecordName}`;
    }
  },
  methods: {
    /**
     * handle event onExpandedPreview
     * @param {Boolean|boolean} value
     */
    onExpandedPreview(value) {
      this.expandedPreview = value;
      this.$emit(eventNames.expandedPreview, value);
    }
  }
};
</script>
